@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot?a1iknf');
	src:url('../fonts/icomoon.eot?a1iknf#iefix') format('embedded-opentype'),
		url('../fonts/icomoon.ttf?a1iknf') format('truetype'),
		url('../fonts/icomoon.woff?a1iknf') format('woff'),
		url('../fonts/icomoon.svg?a1iknf#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .icon {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin icon(){
  font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-sort_by_alpha:before {
    content: "\e053";
}
.icon-link:before {
    content: "\e157";
}
.icon-sort:before {
    content: "\e164";
}
.icon-vertical_align_bottom:before {
    content: "\e258";
}
.icon-vertical_align_top:before {
    content: "\e25a";
}
.icon-schedule:before {
    content: "\e8b5";
}
.icon-expande:before {
    content: "\e06c";
}
.icon-collapse:before {
    content: "\e081";
}
.icon-attach_money:before {
    content: "\e0c1";
}
.icon-folder:before {
    content: "\e102";
}
.icon-headset:before {
    content: "\e10d";
}
.icon-image:before {
    content: "\e159";
}
.icon-before:before {
    content: "\e19a";
}
.icon-next:before {
    content: "\e19b";
}
.icon-camera:before {
    content: "\e1a4";
}
.icon-images:before {
    content: "\e1a5";
}
.icon-document-pdf:before {
    content: "\e1a6";
}
.icon-eye:before {
    content: "\e1a8";
}
.icon-caret-down:before {
    content: "\e201";
}
.icon-caret-up:before {
    content: "\e203";
}
.icon-check:before {
    content: "\e206";
}
.icon-close:before {
    content: "\e209";
}
.icon-up:before {
    content: "\e20a";
}
.icon-down:before {
    content: "\e20b";
}
.icon-nav-less:before {
    content: "\e20f";
}
.icon-nav-more:before {
    content: "\e210";
}
.icon-video:before {
    content: "\e23e";
}
.icon-delete:before {
    content: "\e287";
}
.icon-search:before {
    content: "\e2ca";
}
.icon-compass:before {
    content: "\e909";
}
.icon-thumb-up:before {
    content: "\e908";
}
.icon-nav-contact:before {
    content: "\e904";
}
.icon-nav-portfolio:before {
    content: "\e905";
}
.icon-nav-product:before {
    content: "\e906";
}
.icon-nav-other:before {
    content: "\e907";
}
.icon-whatsapp:before {
    content: "\e903";
}
.icon-linkedin:before {
    content: "\e90a";
}
.icon-google:before {
    content: "\e902";
}
.icon-twitter:before {
    content: "\e900";
}
.icon-facebook:before {
    content: "\e901";
}
.icon-skype:before {
    content: "\e90b";
}
.icon-pinterest:before {
  content: "\f231";
}
.icon-lock:before {
  content: "\e90c";
}
.icon-instagram:before {
  content: "\e90d";
}
