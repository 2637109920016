// Search form
.search-form {
  margin-bottom: 30px;
}

.search-form label {
  display: block;
}

.search-form .search-field {
  @extend .form-control;
  display: block;
  width: 100%;
  height: 60px;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
  border-width: 3px;
  border-radius: 0;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn--p1;
  margin-top: 0.8em;
  padding-left: 2em;
  padding-right: 2em;
}

textarea {
  resize: none;
  overflow: hidden;
  height: 35px;
}

.checkbox {
  margin: 0;

  label {
    position: static;
    line-height: 35px;
  }

  input {
    margin-top: 12px;
  }
}

.form {
  position: relative;
  border-style: solid;
  border-color: darken($border-color, 10%);
  border-width: 0 0 1px 1px;
  transition: all 0.3s;

  button[type="submit"] {
    position: relative;
    top: -5px;
    min-height: 35px;
  }
}

.form__group {
  background-color: #fff;
  &.-disabled {
    background-color: $background-color;
    cursor: not-allowed;
  }
}

.form__field {
  position: relative;
  padding: 20px 10px 8px 10px;
  border-style: solid;
  border-color: darken($border-color, 10%);
  border-width: 1px 1px 0 0;
  transition: all 0.3s;

  &.focus {
    background-color: $background-color;
  }

  &.checkbox {
    min-height: 75px;

    label {
      font-size: 0.8rem;
      color: $text-color;
    }
  }

  &.-uploader {
    padding: 0;
  }
}

.form__control {
  display: block;
  width: 100%;
  padding: 5px 5px;
  border: none;
  outline: none;
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
  }
}

.form__label {
  position: absolute;
  left: 15px;
  top: 25px;
  font-size: 0.9rem;
  font-weight: 600;
  color: $brand-primary;
  transform-origin: left top;
  transition: transform 0.3s;

  .typed & {
    color: lighten($text-color, 30%);
    transform: translateY(-20px) scale(0.8, 0.8);
  }

  .has-error & {
    border-bottom: 1px solid $color-warning;
  }

  .-disabled & {
    opacity: 0.4;
    cursor: not-allowed;
  }

}

// Custom radio box
.radio {

  &.-custom {
    position: relative;
    margin: 0;

    input[type="radio"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      opacity: 0;
      visibility: 0;
      cursor: pointer;

      &:checked + label:before {
        background-color: $brand-primary;
      }

    }

    label {
      display: block;
      padding: 0;
      font-size: 0.9rem;
      font-weight: 600;
      color: $brand-primary;
      line-height: 1;

      &:before {
        content: "";
        display: inline-block;
        width: 23px;
        height: 23px;
        margin-right: 5px;
        border: 2px solid #fff;
        background-color: #fff;
        vertical-align: middle;
        border-radius: 50%;
        box-shadow: 0 0 0 1px $brand-tertiary;
      }
    }
  }
}

.field-type {
  background-color: $background-color;

  .form__label {
    top: 10px;
    font-size: 0.7rem;
    font-weight: 400;
    color: lighten($text-color, 30%);
    text-transform: uppercase;
  }

  .form__field {
    @include clearfix();
    padding-top: 40px;
  }

  .radio {
    float: left;
    width: 33.333333%;
    border-right: 1px solid $border-color;
  }
}

.jFiler-input-button {
  @extend .btn;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 20px 25px;
  color: #fff;
  text-transform: uppercase;
  background-color: lighten($brand-tertiary, 30%);
}

.jFiler-input {
  min-height: 60px;
}

.jFiler-input-caption {
  padding: 18px 15px;
}

.jFiler-items {
  position: relative;
  padding: 6px 15px 6px 40px;
  font-size: 0.8rem;
  border-top: 1px solid darken($border-color, 10%);
}

.jFiler-items-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.jFiler-item-thumb-image {
  display: none;
}

.jFiler-jProgressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;

  .bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    background-color: $brand-primary;
  }
}

.jFiler-item-assets {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .jFiler-item-others {
    position: absolute;
    left: 8px;
    top: 50%;
    margin-top: -10px;
    font-size: 20px;
    line-height: 1;
  }

  .jFiler-item-trash-action {
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -10px;
    font-size: 20px;
    color: $text-color;

    &:hover,
    &:focus {
      color: $brand-secondary;
      text-decoration: none;
    }
  }
}

// Parsley errors style
.parsley-errors-list {
  display: none;
  visibility: hidden;
  opacity: 0;

  &.filled {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 2px 4px;
    font-size: 11px;
    opacity: 1;
    visibility: visible;
    list-style: none;
    color: darken($color-warning, 40%);
    background-color: $color-warning;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 15px;
    width: 0;
    height: 0;
    margin-top: -6px;
    border-style: solid;
    border-width: 6px;
    border-color: $color-warning transparent transparent transparent;
  }
}

// Response
.form__response {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  background-color: hsla(0%, 0%, 100%, 0.85);
  transform: scale(1.1, 1.1);
  transition: transform 0.3s;

  .has-response & {
    opacity: 1;
    visibility: visible;
    transform: scale(1, 1);
  }

  .icon {
    color: $brand-primary;
    font-size: 48px;
  }
}

.form__response__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.protected-download {
  display: none;
}

.protected-download__header {
  text-align: center;

  .icon {
    font-size: 48px;
  }
}

.protected-download__response {
  align-items: center;
  background-color: #ffffff;
  display: none;
  height: 100%;
  left: 0;
  justify-content: center;
  padding: 20px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 100;

  .has-response & {
    display: flex;
  }
}

.protected-download__form {

  label {
    @extend .sr-only;
  }

  .form-control {
    border-radius: 0;
    height: 52px;
    text-align: center;
  }
}

.privacy.checkbox {
  .form__label {
    position: relative;
    left: auto;
    top: auto;
  }
}
