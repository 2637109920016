// Grid system

.wrap {

  .is-loader-working &,
  .has-layer-opened & {
    filter: blur(8px);
  }
}

// Remove padding from Bootstrap grid
.row--nogutter {
  margin: 0;

  > div {
    padding: 0;
  }
}

.postgrid {
  @extend .list-unstyled;

  > li {
    line-height: 1;
  }
}

.postgrid--alternate {

  > li:nth-child(even){
    .erow {
      flex-direction: row-reverse;

      .erow__body {
        background-color: $brand-quater;

        a {
          color: $brand-primary;
        }

        .entry-subtitle {
          color: $brand-tertiary;
        }

      }
    }
  }

}

.postgrid--left {
  .erow {
    flex-direction: row;
  }
}

.postgrid--right {
  .erow {
    flex-direction: row-reverse;
  }
}

.postgrid--c1 {
  .erow__body {
    background-color: $brand-primary;
  }
}

.postgrid--c2 {
  .erow__body {
    background-color: $brand-secondary;
  }
}

//
// Page Loader
//
.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.5,1.5);
  transition: all 0.3s;
  pointer-events: none;

  img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 64px;
    height: 64px;
    margin: auto;
    border-radius: 50%;
  }

  .is-loader-working & {
    visibility: visible;
    opacity: 1;
    transform: scale(1,1);
  }
}

//
// Layer Overlay
//

.layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  transform: scale(1.3, 1.3);
  background-color: hsla(0%, 0%, 100%, 1);
  box-shadow: 0 0 5px rgba(0,0,0,.2);
  transition: all 0.3s ease-in;

  &.is-opened {
    z-index: 1000;
    opacity: 1;
    visibility: visible;
    transform: scale(1,1);
  }

  .admin-bar & {
    margin-top: 32px;
  }

  &.--transparent {
    background-color: transparent;

    .layer__header {
      background-color: #ffffff;
    }
  }

}

.layer__background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(10px);
  overflow: hidden;
}

.layer__header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid $border-color;
  text-align: left;
  background-color: rgba(255,255,255,.6);

}

.layer__close {
  @extend .btn-link;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-left: 1px solid $border-color;

  &:hover,
  &:focus {
    text-decoration: none;
    border-left: 1px solid $border-color;
  }
}

.layer__title {
  padding-right: 50px;
  margin: 0;
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 50px;
}

.layer__body {
  position: absolute;
  top: 50px;
  bottom: 0;
  width: 100%;
  padding: 20px;
  overflow: auto;
}

.layer__box {
  padding: 20px;
  background-color: #fff;
}

.layer__loader {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #fff;

  img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 64px;
    height: 64px;
    margin: auto;
    border-radius: 50%;
  }

  .has-loader & {
    display: block;
  }
}

.mfp-zoom-out {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(1.3);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}

//
// Block Grids
//
// Inspired by Foundation Framework
// @link http://foundation.zurb.com/docs/components/block_grid.html
// --------------------------------------------------

.grid {
  @include clearfix();
  @include list-unstyled();
  padding: 0!important;
  margin-left: -($grid-gutter-width / 2);
  font-size: 0;

  > li {
    display: inline-block;
    float: left;
    width: 100%;
    padding: 0 0 ($grid-gutter-width / 2) ($grid-gutter-width / 2);
    line-height: 1;
    vertical-align: top;
    -webkit-backface-visibility: hidden;
  }

  &.-gutter0 {
    margin: 0;

    > li { padding: 0; }
  }

  &.-border {
    margin: 0;
    border-top: 2px solid $brand-primary;
    border-left: 2px solid $brand-primary;

    > li {
      padding: 0;
      border-bottom: 2px solid $brand-primary;
      border-right: 2px solid $brand-primary;
    }
  }

  /* Mobile first */

  &.grid-2 {
    > li {
      width: 50%;

      &:nth-of-type(n)    { clear: none; }
      &:nth-of-type(2n+1) { clear: both; }
    }
  }

  &.grid-3 {
    > li {
      width: 33.333333%;

      &:nth-of-type(n)    { clear: none; }
      &:nth-of-type(3n+1) { clear: both; }
    }
  }

  &.grid-4 {
    > li {
      width: 25%;

      &:nth-of-type(n)    { clear: none; }
      &:nth-of-type(4n+1) { clear: both; }
    }
  }

  &.grid-5 {
    > li {
      width: 20%;

      &:nth-of-type(n)    { clear: none; }
      &:nth-of-type(5n+1) { clear: both; }
    }
  }

  &.grid-6 {
    > li {
      width: 16.666666%;

      &:nth-of-type(n)    { clear: none; }
      &:nth-of-type(6n+1) { clear: both; }
    }
  }

  /* Small devices breakpoint */

  &.grid-sm-2 {
    @media (min-width: $screen-sm-min) {
      > li {
        width: 50%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(2n+1) { clear: both; }
      }
    }
  }

  &.grid-sm-3 {
    @media (min-width: $screen-sm-min) {
      > li {
        width: 33.333333%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(3n+1) { clear: both; }
      }
    }
  }

  &.grid-sm-4 {
    @media (min-width: $screen-sm-min) {
      > li {
        width: 25%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(4n+1) { clear: both; }
      }
    }
  }

  &.grid-sm-5 {
    @media (min-width: $screen-sm-min) {
      > li {
        width: 20%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(5n+1) { clear: both; }
      }
    }
  }

  &.grid-sm-6 {
    @media (min-width: $screen-sm-min) {
      > li {
        width: 16.666666%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(6n+1) { clear: both; }
      }
    }
  }

  /* Medium devices breakpoint */

  &.grid-md-2 {
    @media (min-width: $screen-md-min) {
      > li {
        width: 50%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(2n+1) { clear: both; }
      }
    }
  }

  &.grid-md-3 {
    @media (min-width: $screen-md-min) {
      > li {
        width: 33.333333%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(3n+1) { clear: both; }
      }
    }
  }

  &.grid-md-4 {
    @media (min-width: $screen-md-min) {
      > li {
        width: 25%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(4n+1) { clear: both; }
      }
    }
  }

  &.grid-md-5 {
    @media (min-width: $screen-md-min) {
      > li {
        width: 20%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(5n+1) { clear: both; }
      }
    }
  }

  &.grid-md-6 {
    @media (min-width: $screen-md-min) {
      > li {
        width: 16.666666%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(6n+1) { clear: both; }
      }
    }
  }

  /* Large devices breakpoint */

  &.grid-lg-2 {
    @media (min-width: $screen-lg-min) {
      > li {
        width: 50%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(2n+1) { clear: both; }
      }
    }
  }

  &.grid-lg-3 {
    @media (min-width: $screen-lg-min) {
      > li {
        width: 33.333333%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(3n+1) { clear: both; }
      }
    }
  }

  &.grid-lg-4 {
    @media (min-width: $screen-lg-min) {
      > li {
        width: 25%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(4n+1) { clear: both; }
      }
    }
  }

  &.grid-lg-5 {
    @media (min-width: $screen-lg-min) {
      > li {
        width: 20%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(5n+1) { clear: both; }
      }
    }
  }

  &.grid-lg-6 {
    @media (min-width: $screen-lg-min) {
      > li {
        width: 16.666666%;

        &:nth-of-type(n)    { clear: none; }
        &:nth-of-type(6n+1) { clear: both; }
      }
    }
  }
}
