.section {
  margin: 50px 0;

  &.-lgrey {
    margin: 0;
    padding: 50px 0;
    border-style: solid;
    border-color: darken(#f5f5f5, 3%);
    border-width: 1px 0;
    background-color: #f5f5f5;
  }

  &.-dgrey {
    margin: 0;
    padding: 50px 0;
    background-color: $brand-tertiary-d;
  }

  &.-image {
    position: relative;
    margin: 0;
    padding: 80px 0 70px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      filter: blur(2px);
      background-color: rgba(255,255,255,.2);
    }
  }
}

//
// Page Breadcrumbs
//
.breadcrumbs {
  margin: 10px 0;
  text-align: right;
  font-size: .7rem;
  text-transform: uppercase;
}

//
// Page Content Layout
//

.has-divider {
  border-left: 1px solid $border-color;
}

.page-content {
  @media(min-width: $screen-sm-min) {
    display: flex;
  }
}

.page-content__main {
  border-left: 1px solid $border-color;
}

.page-content__aside {
  @media(min-width: $screen-sm-min) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }


  .company-profile {
    align-self: flex-end;
    @media(min-width: $screen-sm-min) {
      margin-bottom: 40px;
    }
  }
}


//
// Heading
//
.heading {
  text-align: center;

  h1 {
    text-transform: uppercase;
  }

  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
    color: #7D7D7D;

    @media(min-width: $screen-sm-min) {
      font-size: 1.3rem;
    }
  }

}

//
// Page Header
//
.page-header {
  position: relative;
  margin: 50px 0 50px 0;
  padding: 0 0 20px 0;
  text-align: center;
  border: none;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 200px;
    height: 3px;
    margin-left: -100px;
    background-color: $brand-primary;
  }
}

.page-header__title {
  margin: 0;
  @include font-family-p2();
  text-transform: uppercase;
}

.page-header__subtitle {
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  color: lighten($text-color, 20%);

  @media(min-width: $screen-sm-min) {
    font-size: 1.3rem;
  }
}

.page-header__surtitle {
  text-transform: uppercase;
  color: lighten($text-color, 20%);
}

.map {
  margin-top: 50px;
  height: 200px;

  @media (min-width: $screen-sm-min) {
    height: 500px;
  }
}

.form--contact {
  margin-top: 20px;
}

.error404 {
  .icon-compass {
    font-size: 200px;
  }
}

//
// Cookie Law Banner
//
.cookielaw {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  background-color: #000;
}

.cookielaw__inner {
  padding: 20px;

  p {
    color: #fff;
    font-size: 0.85rem;
    font-weight: 300;
  }
}

.cookielaw__close {
  @extend .btn-link;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 28px;
  color: #fff;

  &:hover,
  &:focus {
    color: $brand-primary;
    text-decoration: none;
  }
}
