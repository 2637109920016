//
// Animations
//
@-webkit-keyframes loading {
	0% {
		transform-origin: center;
		transform:rotate(0deg);
	}
	100% {
		transform-origin: center;
		transform:rotate(360deg);
	}
}

@keyframes loading {
	0% {
		transform-origin: center;
		transform:rotate(0deg);
	}
	100% {
		transform-origin: center;
		transform:rotate(360deg);
	}
}

//
// Buttons
//
.btn {
  position: relative;
  display: inline-block;
  padding: 12px 25px;
  font-size: 0.7rem;
  border-radius: 0;
  white-space: inherit;
  transition: 200ms background cubic-bezier(0.4, 0, 0.2, 1);

  @media(min-width: $screen-sm-min) {
    font-size: 0.8rem;
  }

  .btn-group & {
    border: none;
    box-shadow: none;
  }

  &.-icon {
    padding-top: 12px;
    padding-bottom: 12px;
    text-transform: uppercase;

    .icon {
      font-size: 1.8rem;
      line-height: 0.8rem;
      vertical-align: middle;
    }

  }

  &.active {
    color: #fff;
    background-color: $brand-primary;
  }

  &.-working {
  	color: rgba(0,0,0,0);

  	&:after {
  		width: 26px;
  		height: 26px;
  		margin: -13px 0 0 -13px;
  		border-radius: 100%;
  		border: 4px solid #fff;
  		border-top-color: rgba(0, 0, 0, 0)!important;
  		position: absolute;
  		top: 50%;
  		left: 50%;
  		z-index: 5;
  		-webkit-animation: loading .7s infinite linear;
  		animation: loading .7s infinite linear;
  		content: '';
  		opacity: 1;
  		visibility: visible;
  	}
  }

  &.-success {
  	color: rgba(0,0,0,0);

  	&:after {
  		@extend .icon;
  		content: "\e908";
  		position: absolute;
  		top: 50%;
  		left: 50%;
  		margin-top: -12px;
  		margin-left: -12px;
  		color: #ffffff;
  		font-size: 24px;
  	}
  }

  &:focus,
  &.active,
  &:active {
    outline: 0;
  }
}

.btn--p1 {
  color: #ffffff;
  text-transform: uppercase;
  border: 1px solid rgba(255,255,255,0.4);
  background-color: $brand-primary;
  box-shadow: 0 0 0 3px $brand-primary;

  &:hover,
  &:focus {
    color: #fff;
    background-color: lighten($brand-primary, 3%);
    outline: none;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 3px $brand-primary;
  }

}

.btn--p2 {
  color: #ffffff;
  text-transform: uppercase;
  border: 1px solid rgba(255,255,255,0.4);
  background-color: $brand-secondary;
  box-shadow: 0 0 0 3px $brand-secondary;

  &:hover,
  &:focus {
    color: #fff;
    background-color: lighten($brand-secondary, 3%);
    outline: none;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 3px $brand-secondary;
  }
}

.btn--p3 {
  color: $brand-quater;
  text-transform: uppercase;
  border: 1px solid rgba(255,255,255,0.4);
  background-color: $brand-primary;
  box-shadow: 0 0 0 3px $brand-primary;

  &:hover,
  &:focus {
    color: #fff;
    background-color: lighten($brand-secondary, 3%);
    outline: none;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 3px $brand-tertiary;
  }
}

.btn--block {
  display: block;
  width: 100%;
}

.btn--sm {
  padding: 4px 10px;
  font-size: 0.55rem;
}

.btn-group {
  a {
    color: $text-color;
  }
}

.dropdown {

  &.-filters {
    margin-bottom: 10px;

    @media(min-width: $screen-sm-min) {
      margin-bottom: 0;
    }

    .dropdown-menu {
      width: 100%;
      padding: 20px 20px;
      border-width: 0;

      &:before {
        top: -20px;
        left: 50%;
        border-color: transparent transparent #fff transparent;
      }
    }

    .filters__reset {
      display: block;
      padding: 4px 0;
      border: 1px solid #fff;
      text-align: center;
    }

    dt {
      margin-bottom: 5px;
      color: $brand-primary;
      text-transform: uppercase;
    }

    dd {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-bottom: 1px solid rgba(255,255,255,.1);

      a {
        display: block;
        padding: 4px 5px;
      }
    }
  }

  .dropdown-menu {
    padding: 10px;
    margin: 0;
    border: 2px solid $brand-tertiary;
    border-radius: 0;

    &:before {
      content: "";
      position: absolute;
      left: 20px;
      width: 0;
      height: 0;
      margin-left: -10px;
      border-style: solid;
      border-width: 10px;
      border-color: transparent transparent $brand-tertiary transparent;
    }
  }
}
