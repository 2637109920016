//
// Slideshow
//





.swiper-container {
  height: 90vh;
  overflow: hidden;
}

.swiper-horizontal>.swiper-pagination-bullets {
  left: auto;
  bottom: 40px;
  right: 30px !important;
  width: 100px;
}

.swiper-pagination-bullet {
  width: 13px !important;
  height: 13px !important;
  border: solid 2px white;
  opacity: 1;
  background: none;
}

.slide-caption {
  font-family: 'Poppins';
  font-weight: 600;
  text-align: right;
  font-size: 3rem;
  line-height: 3rem;
  position: absolute;
  right: 50px;
  bottom: 160px;
  width: 90%;
  padding-bottom: 5%;
  color: white;
  text-shadow: 2px 2px 3px rgba(0,0,0,0.4);
  vertical-align: text-bottom;

  @media(min-width: $screen-sm-min) {
    font-size: 6rem;
    line-height: 6rem;
    bottom: 60px;
    width: 50%;
  }
}

.swiper-pagination-bullet-active {
  background: white
}

.swiper-slide {
  background-position: center center;
}
