
.cards {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.padding-0 {
  padding: 0
}

.single-card {
  margin: 6px;
}

.single-card  {
  object-fit: cover;
}

.single-card .title {
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: 'Poppins';
  font-weight: 600;
  text-align: right;
  font-size: 2rem;
  line-height: 3rem;
  position: absolute;
  right: 50px;
  bottom: 20px;
  width: 100%;
  padding-bottom: 5%;
  color: white;
  text-shadow: 2px 2px 3px rgba(0,0,0,0.4);
  vertical-align: text-bottom;

  @media(min-width: $screen-sm-min) {
    font-size: 3rem;
  }

}

.bar {
  height: 3px;
  width: 100px;
  background: white;
  position: absolute;
  bottom: 35px;
  right: 55px;
}
