//
// Featured
//

.featured {

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      position: relative;

      &:before {
        content: "";
        display: none;
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        background-color: #D5D5D5;

        @media(min-width: $screen-sm-min) {
          display: block;
        }
      }

      &:first-child {

        .feature {
          border-top: none;
        }
      }

      &:nth-child(even) {

        .feature {
          flex-direction: column;

          @media(min-width: $screen-sm-min) {
            flex-direction: row-reverse;
          }
        }

        .feature__body {
          text-align: center;

          .no-flexbox & {
            text-align: left;
          }

          @media(min-width: $screen-sm-min) {
            text-align: right;
          }
        }

        .feature__action {

          &:before {
            content: "\e19a";
            left: auto;
            right: -45px;
          }
        }

      }

    }
  }
}

.feature {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
  border-top: 1px solid #D5D5D5;

  @media(min-width: $screen-sm-min) {
    border-top: none;
    flex-direction: row;
  }

  .no-flexbox & {
    display: table;
  }
}

.feature__object,
.feature__body {
  width: 100%;
  padding: 10px;

  .no-flexbox & {
    display: table-cell;
    vertical-align: middle;
  }

  @media(min-width: $screen-sm-min) {
    width: 50%;
    padding: 25px;
  }
}

.feature__object {
  text-align: center;

  img {
    margin: 0 auto;
  }
}

.feature__body {
  padding: 10px 20px;
  text-align: center;

  @media(min-width: $screen-sm-min) {
    padding: 0 30px;
    text-align: left;
  }

  h2 {
    position: relative;
    margin: 0;
    margin-bottom: 10px;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $brand-primary;
  }

  p {
    margin-bottom: 20px;
    font-size: 1rem;
    font-weight: 300;
  }
}

.feature__action {
  position: relative;
  padding-bottom: 3px;
  border-bottom: 2px solid $brand-primary;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:before {
    @include icon();
    content: "\e19b";
    display: none;
    position: absolute;
    top: -5px;
    left: -45px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    background-color: darken($background-color, 25%);
    border-radius: 50%;

    @media(min-width: $screen-sm-min) {
      display: block;
    }
  }
}
