         // Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #951e1f;
$brand-secondary:       #f9a59a;
$brand-tertiary:        #621212;
$brand-quater:          #fcdfd9;

$brand-tertiary-l:      #4A4A4A;
$brand-tertiary-d:      #303030;

$color-warning:         #F2C078;

$heading-color:         $brand-tertiary-l;
$border-color:          #f5f5f5;
$background-color:      #f5f5f5;
$text-color:            #585858;
