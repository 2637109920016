.footer {
  margin-top: 40px;

  .home &,
  .page-template-template-contact &,
  .single-portfolio & {
    margin-top: 0;
  }

}

.footer__top {
  padding: 50px 0;
  font-size: 0.75rem;
  color: #B7B7B7;
  background-color: $brand-tertiary-l;

  .company {

  }

  .vcard {
    margin: 0 0 0 10px;

    dt {
      font-size: 0.8rem;
      color: #fff;
    }

    dd {
      font-size: 0.7rem;
    }
  }

  h3 {
    margin: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #ffffff;
  }

  .list {
    text-transform: uppercase;
    > li {
      border: none;

      > a {
        padding: 0;
        font-size: 0.7rem;
        color: lighten($text-color, 30%);
      }
    }
  }

  .col4o4 {
    margin-top: 30px;

    @media(min-width: $screen-sm-min) {
      margin-top: 0;
    }
  }

}

.footer__bottom {
  padding: 10px;
  font-size: 0.7rem;
  color: #B7B7B7;
  border-bottom: 4px solid $brand-primary;
  background-color: $brand-tertiary-d;

  &,
  a {
    font-size: 0.7rem;
    color: #B7B7B7;
  }

  p {
    margin: 0;
    padding: 0;
  }

  div[class^="col"] {

    &:nth-of-type(1) {
      text-align: left;
    }
    &:nth-of-type(2) {
      text-align: right;
    }
  }
}

.newsletter__field {
  @extend .form-control;
  height: 44px;
  border: none;
  box-shadow: 0 0 0 3px #ffffff;
  border-radius: 0;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px darken($background-color, 15%);
  }
}
