.sidebar {
  font-size: 0.8rem;
}

//
// Widget general
//
.widget {
  margin-bottom: 30px;
}

.widget__title {
  padding-bottom: 0.3rem;
  text-transform: uppercase;
  font-size: 0.78rem;
  border-bottom: 1px solid $border-color;
}

//
// Share
//
.share {
  margin: 0!important;
  margin-left: -5px;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    margin: 0 0 5px 5px;
    line-height: 1;
  }

  a {
    display: block;
    width: 28px;
    height: 28px;
    text-align: center;
    border-radius: 2px;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .icon {
    line-height: 28px;
    vertical-align: middle;
    color: #fff;
  }
}

.share__facebook {
  background-color: #3A5795;
}

.share__twitter {
  background-color: #53A9EA;
}

.share__google {
  background-color: #d73d32;
}

.share__linkedin {
  background-color: #0177B5;
}

.share__whatsapp {
  background-color: #65BC54;
}

.share__pinterest {
  background-color: #c72527;
}
