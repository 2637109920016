.nav {
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

    > li {
      margin: 0;
      padding: 0;
    }

  &.-inline {
    > ul {

      > li {
        display: inline-block;
      }
    }
  }

  &.-social {
    margin-top: 20px;

    ul {
      margin-left: -5px;
    }

    li {
      margin-left: 5px;
    }

    a {
      display: block;

      &.facebook {
        background-color: #3b5998;

        &:hover,
        &:focus {
          background-color: darken(#3b5998, 5%);
        }
      }

      &.instagram {
        background: radial-gradient(circle at 33% 100%, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 85%, #515ECF);

        &:hover,
        &:focus {
          background-color: darken(#3b5998, 5%);
        }
      }

      &.twitter {
        background-color: #4cc2ff;

        &:hover,
        &:focus {
          background-color: darken(#4cc2ff, 5%);
        }
      }

      &.pinterest {
        background-color: #c91a22;

        &:hover,
        &:focus {
          background-color: darken(#c91a22, 5%);
        }
      }

      &.google {
        background-color: #d23e2b;

        &:hover,
        &:focus {
          background-color: darken(#d23e2b, 5%);
        }
      }

      &.linkedin {
        background-color: #007bb6;

        &:hover,
        &:focus {
          background-color: darken(#007bb6, 5%);
        }
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }


    }

    .icon {
      display: block;
      width: 30px;
      height: 30px;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
    }

    .archiproducts {
      vertical-align: top;

      a {
        border-bottom: 0;
      }

      img {
        max-height: 34px;
      }
    }
  }
}

.filter {
  display: inline-block;

  > ul {
    @extend .list-inline;

    > li > ul {
      display: none;
    }
  }

  > li {
    border: 1px solid darken($background-color, 5%);
  }
}
