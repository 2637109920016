//
// Typography
//

@mixin font-family-p1() {
  font-family: 'Poppins', sans-serif;
}

@mixin font-family-p2() {
  font-family: 'Poppins', sans-serif;
}

@mixin font-family-p3() {
  font-family: 'Poppins', sans-serif;
}

.ff-p1 {
  font-family: 'Poppins', sans-serif;
}

.ff-p2 {
  font-family: 'Poppins', sans-serif;
}

.dblock {
  display: block;
}

.dinline {
  display: inline;
}

.mt {
  margin-top: 30px;
}

.mt-s {
  margin-top: 20px;
}

.mt-xs {
  margin-top: 10px;
}

.mb {
  margin-bottom: 30px;
}

.mb-s {
  margin-bottom: 20px;
}

.mb-xs {
  margin-bottom: 10px;
}

.tup {
  text-transform: uppercase;
}

.txs {
  font-size: 0.65rem;
}

.f300 {
  font-weight: 300;
}

.f400 {
  font-weight: 400;
}

.f700 {
  font-weight: 700;
}

.c-b1 {
  color: $brand-primary;
}

.c-b2 {
  color: $brand-secondary;
}

.c-b3 {
  color: $brand-tertiary;
}

h1, .h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: $heading-color;

  @media(min-width: $screen-sm-min) {
    font-size: 2rem;
  }
}

h2, .h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  font-weight: 400;

  @media(min-width: $screen-sm-min) {
    font-size: 1.35rem;
  }
}

.bg {
  background-color: $background-color;
}

html {
  font-size: 18px;
}

body {
  @include font-family-p1();
  font-size: 16px;
  font-weight: 400;
  color: $text-color;
  margin-top: 0.1px;

  @media($screen-sm-min) {
    font-size: 18px;
  }

  &.has-layer-opened {
    overflow: hidden;
  }
}

.heading-sep {
  position: relative;
  padding: 0 0 15px 0;
  margin: 0 0 15px 0;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 200px;
    height: 3px;
    margin-left: -100px;
    background-color: $brand-primary;
  }
}

a {
  color: $brand-primary;

  &:hover,
  &:focus {
    color: lighten($brand-primary, 10%);
  }
}

.flex {
  @media(min-width: $screen-sm-min) {
    display: flex;
    align-items: center;
  }
}

.flex--divider {
  > *:first-child {
   border-right: 1px solid $border-color;
  }
}

.flex--column {
  @media(min-width: $screen-sm-min) {
    flex-direction: column;
  }
}
