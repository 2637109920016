body#tinymce {
  margin: 12px !important;

  // Header
  h2, h3 {
    color: $brand-primary;
    text-transform: uppercase;
  }

  // Link
  a {
    padding-bottom: .08em;
    border-bottom: .08em solid rgba(#777777, 0.3);

    &:hover,
    &:focus {
      color: $brand-primary;
      text-decoration: none;
      border-color: $brand-primary;
    }
  }

  // Paragraph
  p {
    margin: 30px 0;
    line-height: 1.6;
  }
}
