//
// Image thumbnail
//
.thumb {
  display: inline-block;
  position: relative;
  margin: 0;
  z-index: 1;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    border: 2px solid rgba(255,255,255,0.4);
    pointer-events: none;
  }


  &.-xl {
    &:after {
      border-width: 10px;
    }
  }

  &.-dark {
    &:after {
      border-color: rgba(0,0,0,0.2);
    }
  }

  &.-round {
    border-radius: 50%;
    overflow: hidden;

    &:after {
      border-radius: 50%;
    }
  }
}

.fxhover {
  display: block;
  position: relative;
  overflow: hidden;
  outline: none;

  &:hover,
  &:focus {
    outline: inherit;
  }

  &:hover {
    .fxhover__overlay {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }
}

.fxhover__overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
  z-index: 1;
  margin: auto;
  visibility: hidden;
  border: 10px solid rgba(255,255,255,0.4);
  opacity: 0;
  border-radius: 50%;
  transform: scale(1.1);
  transition: all 0.3s;

  > span {
    display: block;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    font-size: 60px;
    color: rgba(255,255,255,0.8);
  }
}

//
// Lists
//
.list {
  list-style: none;
  margin: 0!important;
  padding: 0;

  > li {
    border-bottom: 1px solid $border-color;

    a {
      display: block;
      padding: 0.3em 0;
      color: $text-color;
      text-decoration: none;
      border-bottom: none;
    }
  }
}

.list--attachments {

  .icon {
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    color: $brand-primary;
  }

  a {
    display: block;
    padding: 5px 0;
    color: $text-color;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: $background-color;
    }
  }
}

//
// Post Toolbar
//

.post-toolbar {
  margin-bottom: 50px;
  padding: 10px;
  text-align: left;
  background-color: $background-color;

  &.is_stuck {
    z-index: 90;

    .badge {
      width: 70px;
      height: 70px;
    }

    .badge__icon {
      padding: 10px 0 5px 0;
      font-size: 18px;
    }

    .badge__label {
      font-size: .5rem;
    }
  }

  @media(max-width: $screen-xs-max) {
    .badge {
      width: 40px;
      height: 40px;

      .badge__icon {
        font-size: 18px!important;
      }

      .badge__label {
        display: block;
        width: 60px;
        margin-top: 5px;
        margin-left: -10px;
        overflow: auto;
        color: $text-color;
        font-size: 9px;
      }
    }
  }

  .share {
    display: inline-block;
    margin-top: 10px!important;

    @media(min-width: $screen-sm-min) {
      margin-left: 10px;
      margin-top: 0;
    }
  }
}

.post-toolbar__inner {
  text-align: center;

  @media(min-width: $screen-sm-min) {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .is_stuck & {
    justify-content: flex-start;
  }
}

.post-toolbar__title {
  display: none;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 0.8rem;

  .is_stuck & {

    @media(min-width: $screen-sm-min) {
      display: inline-block;
    }
  }
}

.table-tech {
  a {
    color: $text-color;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .icon {
    color: $brand-primary;
    font-size: 30px;
  }
}

//
// Entry Content
//
.entry-content {

  // Header
  h2, h3, h4, h5 {
    position: relative;
    color: $brand-primary;
    text-transform: uppercase;

    /*
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -1px;
      left: -20px;
      width: 10px;
      height: 2px;
      background-color: $brand-tertiary;
    }
    */
  }

  figure {

    margin-bottom: 40px;

    img {
      @extend .img-responsive;
    }
  }

  .wp-caption-text {
    color: lighten($text-color, 20%);
    text-align: center;
    border-bottom: 1px solid $border-color;
  }

  // Link
  a {
    padding-bottom: .08em;
    border-bottom: .08em solid rgba(#777777, 0.3);

    &:hover,
    &:focus {
      color: $brand-primary;
      text-decoration: none;
      border-color: $brand-primary;
    }
  }

  // Paragraph
  p, ul, ol {
    @include font-family-p3();
    margin: 30px 0;
    font-weight: 300;
    line-height: 1.7;
  }

  // Blockquote
  blockquote {
    @include font-family-p2();
    border-color: $brand-primary;
    background-color: $background-color;

    p {
      margin: 0;
    }
  }

  .gallery {
    @include clearfix();
    margin: 30px 0;
  }

  .gallery-item {
    float: left;
    margin: 0;
    padding: 5px;

    img {
      border: 1px solid $border-color;
      width: 100%;
    }
  }

  .gallery-columns-3 {
    .gallery-item {
      width: 50%;

      &:nth-of-type(2n+1) {
        clear: both;
      }
    }
  }

  .gallery-columns-3 {
    .gallery-item {
      width: 33.333333%;

      &:nth-of-type(3n+1) {
        clear: both;
      }
    }
  }

  .gallery-columns-4 {
    .gallery-item {
      width: 25%;

      &:nth-of-type(4n+1) {
        clear: both;
      }
    }
  }

  .gallery-columns-5 {
    .gallery-item {
      width: 20%;

      &:nth-of-type(5n+1) {
        clear: both;
      }
    }
  }

}

.entry-summary {
  @include font-family-p2();
  color: $brand-primary;
  font-size: 1.1rem;

  @media(min-width: $screen-sm-min) {
    text-align: right;
  }
}

.entry-content-asset {
  position: relative;
  padding-bottom: 56%;
  margin: 40px 0;

  iframe,
  object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.entry-content__row {
  border-style: solid;
  border-color: $border-color;
  border-width: 0 0 1px 0;

  h2 {
    margin: 0px;
    padding: 20px 5px;
    cursor: pointer;

    &:before {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: $background-color;

      .icon {
        background-color: #ffffff;
      }
    }

  }

  .icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: darken($background-color, 50%);
  }
}

//
// Gallery
//
.gallery {
  margin-top: 50px;
}

.badge {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
  text-align: center;
  border: 1px solid rgba(255,255,255,0.8);
  background-color: $brand-primary;
  border-radius: 50%;
  box-shadow: 0 0 0 2px $brand-primary;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $brand-secondary;
    box-shadow: 0 0 0 2px $brand-secondary;
  }

  .badge__icon {
    display: block;
    padding: 10px 0;
    margin: 0 10px;
    color: #ffffff;
    font-size: 30px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
  }

  .badge__label {
    font-size: 0.7rem;
    line-height: 0.7rem;
    text-transform: uppercase;
    color: #ffffff;
  }
}

//
// Post preview, Row layout
//
.erow {
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: $screen-sm-min) {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 0;

    .no-flexbox & {
      display: table;
      border-spacing: 5px;
    }
  }
}

.erow__object {
  margin: 3px;

  .no-flexbox & {
    display: table-cell;
    vertical-align: middle;
  }

  @media (min-width: $screen-sm-min) {
    width: 50%;
  }

  .thumb {
    margin: 0;
  }
}

.erow__body {
  margin: 3px;
  padding: 10px 5px;
  color: $brand-quater;
  background-color: $brand-primary;
  transition: background-color 0.3s;

  .no-flexbox & {
    display: table-cell;
    vertical-align: middle;
  }

  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 10px 25px;
  }

  .entry-title {
    margin: 0;
    //text-transform: uppercase;
    font-weight: bolder;
  }

  .entry-subtitle {
    margin: 5px 0 0 0;
    font-size: 0.85rem;
    font-weight: 300;
    color: hsla(0%, 0%, 100%, 0.8);

    @media (min-width: $screen-sm-min) {
      margin: 10px 0 0 0;
      font-size: inherit;
    }
  }

  a {
    color: $brand-quater;

    &:hover,
    &:focus {
      color: rgba(255,255,255,0.7);
      text-decoration: none;
    }
  }
}

//
// Post preview, Grid layout
//
.egrid {
  line-height: 1;
  overflow: hidden;
  position: relative;

  &.-team {
    padding: 20px;
  }

  &.egrid--catalog {
    margin-bottom: 30px;
  }
}

.egrid__object {
  .-team & {
    margin: 0 30px;
  }
}

.egrid__header {
  text-align: center;

  h2 {
    color: $brand-tertiary;
    font-size: 0.9rem;
    margin: 0.0 0 1rem;
    text-transform: uppercase;
  }
}

.egrid__footer {
  padding: 5px;
  text-align: center;
}

.egrid__body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 5px;
  text-align: center;
  background-color: rgba($brand-tertiary, .8);

  h2, dt {
    margin: 0;
    font-size: 0.8rem;
    color: #ffffff;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .-team & {
      color: $brand-primary;
      font-weight: 600;
    }
  }

  .-team & {
    position: static;
    margin: 10px 0 0 0;
    background-color: transparent;
  }
}

.vcard {
  margin: 0;

  dd {
    font-size: 0.8rem;
    line-height: 1rem;
    color: lighten($text-color, 30%);
  }

  a {
    color: $brand-secondary;
  }

  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.vcard__role {
  min-height: 40px;
  margin-bottom: 5px;
  color: $text-color;
  border-bottom: 1px solid $border-color;
}

.vcard__skype {
  display: inline-block;
  margin-left: 5px;
  padding-left: 5px;
  color: $brand-primary;
  border-left: 1px solid $border-color;
}
