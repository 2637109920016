.call2action {
  position: relative;
  text-align: center;
  overflow: hidden;

  &.-dgrey {
    overflow: visible;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      width: 40px;
      height: 40px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $brand-tertiary-d;
    }

    &:before {
      top: -20px;
    }

    &:after {
      bottom: -20px;
    }
  }

  &.-image {
    border: $brand-tertiary solid;
    border-width: 6px 0;
  }
}

.call2action__content {
  position: relative;
  z-index: 1;
  margin-bottom: 20px;


  p {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0 1px 3px rgba(0,0,0,.5);

    .-dgrey & {
      color: #fff;
      font-weight: 300;
      text-shadow: none;
    }

    .-lgrey & {
      color: $text-color;
      font-weight: 300;
      text-shadow: none;
    }
  }
}

.call2action__action {
  position: relative;
  z-index: 1;
  margin: 10px auto;
}

.call2action__divider {
  display: block;

  > span {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0 1rem;
    font-size: 0.8rem;
    color: #fff;
    text-transform: uppercase;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      z-index: 0;
      width: 100%;
      height: 1px;
      background-color: $brand-tertiary-l;
    }

    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
  }
}

.call2action_image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  filter: blur(6px);
}

.call2action__more {
  color: #ffffff;
  margin-top: 10px;
}
