$carousel_control_icon_size: 48px;

.carousel {
  position: relative;
  padding: 0 48px;

  .owl-stage-outer {
    border-style: solid;
    border-color: $brand-primary;
    border-width: 2px 2px 0 2px;
  }

  .owl-item {
    border-style: solid;
    border-color: $brand-primary;
    border-width: 0 2px 2px 0;
  }
}

.carousel__nav {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -($carousel_control_icon_size / 2);

}

.carousel__control-prev,
.carousel__control-next {
  position: absolute;
  top: 0;
  font-size: $carousel_control_icon_size;
  color: $brand-primary;

  &.disabled {
    opacity: 0.3;

    &:hover,
    &:focus {
      color: $brand-primary;
    }
  }

  &:hover,
  &:focus {
    color: $brand-secondary;
  }
}

.carousel__control-prev {
  left: 0;
}

.carousel__control-next {
  right: 0;
}

@media(max-width: $screen-sm-min) {
  .carousel__control-prev,
  .carousel__control-next {
    display: none;
  }
}
