//
// Hero
//
.hero {
  position: relative;
  z-index: 0;
  margin: 0;
  overflow: hidden;
  background-color: $brand-tertiary;

  img {
    display: block;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }
}

.hero__object {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero__content {
  @include font-family-p2();
  padding: 1.1rem;
  color: #fff;
  text-align: center;

  @media (min-width: $screen-sm-min) {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
  }

  .-product & {
    padding: 0;
    bottom: 0;

    .page-header {
      margin: 0;
      padding-top: 10px;
      background-color: rgba(0,0,0,.6);
    }

    .page-header__subtitle {
      margin: 0;
      font-size: 1rem;
      font-weight: 300;
      color: lighten($text-color, 20%);

      @media(min-width: $screen-sm-min) {
        font-size: 1.3rem;
      }
    }
  }

  h1 {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    color: #ffffff;

    @media (min-width: $screen-md-min) {
      font-size: 2.5rem;
      text-shadow: 0 0 6px rgba(0,0,0,.4);
    }

  }

  p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;

    @media (min-width: $screen-md-min) {
      font-size: 2rem;
      text-shadow: 0 0 5px rgba(0,0,0,.4);
    }
  }

  .btn {
    margin-top: 20px;
  }

}
