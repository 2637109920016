.pagination {
  margin-top: 30px;
  font-size: 0.8rem;
  border-style: solid;
  border-color: $border-color;
  border-width: 1px 0;

  @media(min-width: $screen-sm-min) {
    text-align: right;
  }

  .page-numbers {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-style: solid;
    border-color: $border-color;
    border-width: 0 1px;

    &.current {

      &,
      &:hover,
      &:focus {
        color: #ffffff;
        background: radial-gradient( circle, $brand-primary 0px, $brand-primary 15px, #ffffff 16px);
      }
    }

    &:hover,
    &:focus {
      color: #ffffff;
      text-decoration: none;
      background: radial-gradient( circle, $brand-tertiary-l 0px, $brand-tertiary-l 15px, #ffffff 16px);
    }
  }

}

.pagination__label {
  padding: 0 10px;
  text-transform: uppercase;
}
